import React, { ReactNode, useEffect, useState } from 'react';
import SearchHeader from '../../../components/Search/components/SearchHeader/SearchHeader';
import { gql, NetworkStatus, useQuery } from '@apollo/client';
import { getDefaultSEO, renderItems } from '../../../utils';
import { IArticleExcerpt } from '../../../components/ContentTypes/Article/Article.def';
import SearchPagination from '../../../components/Search/components/SearchPagination/SearchPagination';
import Loading from '../../../components/base/Loading/Loading';
import Seo from '../../../components/Seo';
import { MAX_NUMBER_OF_POSTS_PER_PAGE } from '../../../constants';
import { PageProps } from 'gatsby';
import { getLatestDate } from '../../../utils/helpers/dateTime';
import { isLocalStorageAllowed } from '../../../utils/helpers/localStorage';
import { WindowLocation } from '@reach/router';
import HTPageWrapper from '../../../wrappers/HTPageWrapper';

interface ISearchPageProps {
  search: string;
  location: WindowLocation;
} 

const SearchPage = ({ search, location }: ISearchPageProps) => {
  const [maxPage, setMaxPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState(search);

  if (isLocalStorageAllowed()) {
    window.localStorage.removeItem('token');
  }

  const { data, fetchMore, loading, error, networkStatus } = useQuery(
    gql`
      query ($searchTerm: String, $first: Int, $cursor: String) {
        posts(where: { search: $searchTerm }, first: $first, after: $cursor) {
          edges {
            node {
              id
              dateGmt
              modifiedGmt
              excerpt
              uri
              title
              categories {
                nodes {
                  id
                  name
                  uri
                }
              }
              author {
                node {
                  uri
                  name
                }
              }
              featuredImage {
                node {
                  sourceUrl
                  altText
                }
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
            hasPreviousPage
          }
        }
      }
    `,
    {
      variables: {
        searchTerm: searchTerm,
        first: MAX_NUMBER_OF_POSTS_PER_PAGE,
        cursor: undefined,
      },
      skip: !searchTerm,
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    if (search !== searchTerm) {
      setSearchTerm(search);
    }
  }, [search]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.setVariables) {
      if (maxPage !== 1 && currentPage !== 1) {
        setCurrentPage(1);
        setMaxPage(1);
      }
    }
  }, [networkStatus]);

  const renderSearchPage = (children: ReactNode) => {
    return (
      <>
        <SearchHeader
          setSearchTerm={setSearchTerm}
          title={searchTerm}
          disableSearchInput={loading}
        />
        <div className="search-container">{children}</div>
      </>
    );
  };

  if (!searchTerm) {
    return renderSearchPage(<h4>Please enter search term.</h4>);
  }
  if (loading) {
    return renderSearchPage(<Loading />);
  }

  if (error || !data) {
    return renderSearchPage(
      <h4 className="error">
        Sorry, but nothing matched your search terms. Please try again with some different keywords.
      </h4>
    );
  }

  const posts: Queries.WpPostConnection = data.posts;
  const maxIndex = currentPage * MAX_NUMBER_OF_POSTS_PER_PAGE - 1;
  const edges = posts.edges.slice(maxIndex - (MAX_NUMBER_OF_POSTS_PER_PAGE - 1), maxIndex + 1);
  const pageInfo = data.posts.pageInfo;
  const articles: IArticleExcerpt[] = edges.map(({ node }) => {
    const { modifiedGmt, dateGmt, excerpt, uri, featuredImage, title, categories, author } = node;
    const date = getLatestDate(dateGmt, modifiedGmt);
    return {
      date,
      excerpt: excerpt || '',
      link: uri || '#',
      thumbnail: {
        src: featuredImage?.node?.sourceUrl || '',
        alt: featuredImage?.node?.altText || '',
      },
      title: title || '',
      category: {
        text: (categories?.nodes && categories?.nodes[0]?.name) || '',
        link: (categories?.nodes && categories?.nodes[0]?.uri) || '',
      },
      author: {
        text: author?.node?.name || '',
        link: author?.node?.uri || '#',
      },
    };
  });

  const nextDisabled = currentPage === maxPage && !pageInfo.hasNextPage;
  const prevDisabled = currentPage === 1;
  const linkClick = () => {};
  const handleNext = () => {
    if (currentPage === maxPage && pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          searchTerm: searchTerm,
          first: MAX_NUMBER_OF_POSTS_PER_PAGE,
          cursor: pageInfo.endCursor,
        },
        updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
          const newEdges = fetchMoreResult.posts.edges;
          const pageInfo = fetchMoreResult.posts.pageInfo;

          if (newEdges.length) {
            const newPage = maxPage + 1;
            setCurrentPage(newPage);
            setMaxPage(newPage);
            return {
              posts: {
                edges: [...previousResult.posts.edges, ...newEdges],
                pageInfo,
              },
            };
          }
          return previousResult;
        },
      });
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    setCurrentPage(currentPage - 1);
  };

  return (
    <HTPageWrapper category='Search' title={'Search: ' + searchTerm} location={location}><div className="search-page">
      {renderSearchPage(
        <>
          {articles.length > 0 ? (
            <section className="guideBlocks">
              <div className="container">
                <div className="newsContainer">
                  {renderItems(articles, 'ArticleExcerpt', linkClick)}
                </div>
              </div>
            </section>
          ) : (
            <h4>No results.</h4>
          )}
        </>
      )}
      {(pageInfo.hasNextPage || posts.edges.length > MAX_NUMBER_OF_POSTS_PER_PAGE) && (
        <div className="container">
          <SearchPagination
            nextDisabled={nextDisabled}
            prevDisabled={prevDisabled}
            handleNext={handleNext}
            handlePrev={handlePrev}
          />
        </div>
      )}
    </div>
    </HTPageWrapper>
  );
};

export const Head = ({ params, location }: PageProps) => {
  let pageTitle = '';

  if (params['search']) {
    pageTitle = `You searched for: ${params?.['search']?.charAt(0).toUpperCase()}${params?.[
      'search'
    ]?.slice(1)}`;
  }
  const socialTitle = pageTitle;
  const ogDescription = '';
  const includeCollectionPageType = true;

  const seo = getDefaultSEO(
    location.pathname,
    pageTitle,
    socialTitle,
    ogDescription,
    includeCollectionPageType
  );
  return <Seo title={pageTitle} wpSeo={seo} />;
};

export default SearchPage;
