import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

// Extend dayjs with the utc plugin
dayjs.extend(utc);

/**
 * Function to determine if the passed-in date and time (string) is more recent than 48 hours ago
 */
export const isRecentlyPublished = (publishedTimeGmt: string | undefined) => {
  if (!publishedTimeGmt) return false;
  const nowMinus48Hours = new Date();
  nowMinus48Hours.setHours(nowMinus48Hours.getHours() - 48); // subtracts 48 hours
  const timestamp48HoursAgo = nowMinus48Hours.getTime();
  const publishedTimestamp = new Date(publishedTimeGmt).getTime();
  return publishedTimestamp > timestamp48HoursAgo;
};

/**
 * Function to get the human readable date from a timestamp retrieved from WordPress
 * @param dateTime - the timestamp from WordPress (date and time in the form of a string)
 * @returns Human readable date as a string
 */
export const getDateFromWpDateTime = (dateTime: string | null) => {
  if (!dateTime) return '';
  return new Date(dateTime).toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};


/**
 * This function compares published date and modified date then return the latest.
 * This adjustment is required for those cases where the modified date is lower than the publish
 * date. This happens when a post has been published from a schedule.
 */
export const getLatestDate = (
  datePublished?: Queries.Maybe<string>,
  dateModified?: Queries.Maybe<string>
) => {
  let updatedDate = dateModified;
  if (datePublished && dateModified) {
    updatedDate = new Date(dateModified) > new Date(datePublished) ? dateModified : datePublished;
  }
  return updatedDate;
};

/**
 * Function to convert a date string to the passed-in timezone offset
 * and display it in the format: YYYY-MM-DDTHH:MM:SS[+/-]HH:MM
 * @param date (string) The date object to convert and display
 * @param tzOffset (number) The timezone offset to use when diplaying the date
 * @returns string
 */
export const formatDateWithUTCOffset = (date: string, tzOffest: number): string => {
  return dayjs(date).utcOffset(tzOffest).format();
};
