import React, { FC, useState } from 'react';
import SearchInput from '../../../base/SearchInput/SearchInput';
import { ISearchHeader } from './SearchHeader.def';

const SearchHeader: FC<ISearchHeader> = ({ title, setSearchTerm, disableSearchInput = false }) => {
  const [searchText, setSearchText] = useState(title || '');

  const executeSearch = () => {
    setSearchTerm(searchText);
  };

  return (
    <div className="searchHeader">
      <div className="container">
        <SearchInput
          executeSearch={executeSearch}
          searchText={searchText}
          setSearchText={setSearchText}
          disabled={disableSearchInput}
        />
        {title && (
          <h1>
            Search results for: <span className="searchKey">{title}</span>
          </h1>
        )}
      </div>
    </div>
  );
};

export default SearchHeader;
