import React, { FC } from 'react';
import { ISearchPagination } from './SearchPagination.def';

const SearchPagination: FC<ISearchPagination> = ({
  handleNext,
  handlePrev,
  nextDisabled = false,
  prevDisabled = false,
}) => {
  const scrollToTop = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  };
  return (
    <div className="pagination search-pagination">
      <button
        onClick={() => {
          scrollToTop();
          handlePrev();
        }}
        disabled={prevDisabled}
        className="prev"
      >
        <span>PREVIOUS</span>
      </button>
      <button
        onClick={() => {
          scrollToTop();
          handleNext();
        }}
        disabled={nextDisabled}
        className="next"
      >
        <span>NEXT</span>
      </button>
    </div>
  );
};

export default SearchPagination;
